import { getUrlParameter } from '~/helpers/utils';

const MONARCH_RULE_SET = 'cart_CDAPI_Test_Rule_Set';
// Any url param formatting for the Monarch CDAPI Request payload
const constructCdapiPayload = () => {
  const payload = {
    campaignID: getUrlParameter('campaignID'),
    utm_term: getUrlParameter('utm_term'),
  };
  return payload;
};

const callMonarchWithPreamp = (preampDecisions) => {
  if (typeof preampDecisions === 'object') {
    // check editable asset property
    let cdapiPlacement = '';
    let cdapiMonarchRule = '';

    Object.entries(preampDecisions).forEach(([placementId, asset]) => {
      const { metadata = {} } = asset;
      if (
        metadata.isCdapiAgent &&
        typeof metadata.monarchCustomRuleId === 'string'
      ) {
        cdapiMonarchRule = metadata.monarchCustomRuleId;
        cdapiPlacement = placementId;
      }
    });

    if (cdapiMonarchRule && cdapiPlacement) {
      const payload = constructCdapiPayload();
      const config = {
        rulesetId: MONARCH_RULE_SET,
        payload,
      };

      return config;
    }
  }
  return false;
};

export default callMonarchWithPreamp;
