import React, { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';
import {
  CohesionContext,
  CohesionScript,
  withCohesion,
} from '@red-digital/bricks';
import NewRelic from '~/components/util/NewRelic';

// Redux
import { store } from '~/redux/store';
import { Provider } from 'react-redux';

// Components
import NavHeader from '~/components/sections/Navheader';
import Footer from '~/components/sections/Footer';
import { Preamp } from '@red-digital/bricks';

// Providers
import { LegalProvider } from '~/providers/LegalProvider';
import { CallCenterProvider } from '~/providers/CallCenterProvider';

// Helpers
import { setMCID, getUrlParameter, sanitizeNetworkData } from '~/helpers/utils';
import Script from 'next/script';

import listOfPreampAssets from 'preamp/listOfPreampAssets';
// Global CSS - Foundation Grid
import '@red-digital/react-devkit/scss/foundation/foundation.scss';
import callMonarchWithPreamp from '~/helpers/hooks/callMonarchWithPreamp';
import { logError } from '@red-digital/bricks';
import '~/scss/bricks.scss';

LogRocket.init('0jc5un/hughesnet-cart', {
  release: process.env.release,
  network: {
    requestSanitizer: sanitizeNetworkData(['suggestions']),
    responseSanitizer: sanitizeNetworkData(['suggestions']),
  },
});
function MyApp({ Component, pageProps }) {
  const { events } = useRouter();
  const [updateFuse, setUpdateFuse] = useState(false);
  const { monarchResponse, preampDecisions } = useContext(CohesionContext);
  const [logRocketIdentify, setLogRocketIdentify] = useState(false);

  useEffect(() => {
    // Set MarketingCodeId
    setMCID();
    // Executes when route is finish loading
    events.on('routeChangeComplete', () => {
      // Tagular Page View
      window.tagular && window.tagular('pageView');

      // New Relic Page View
      if (window && window.newrelic) newrelic.interaction().save();
    });
  }, []);

  useEffect(() => {
    if (!logRocketIdentify) {
      setLogRocketIdentify(true);
      window?.cohesion('ready', () => {
        LogRocket.identify(window._Cohesion.sessionId);
      });
    }
  }, [logRocketIdentify]);

  useEffect(() => {
    // Set fuse metadata from url params
    if (!updateFuse) {
      setUpdateFuse(true);
      window?.cohesion('fuse:done', () => {
        if (!getUrlParameter('campaignID') && !getUrlParameter('tfn')) {
          return;
        }
        const campaignId = getUrlParameter('campaignID') || '';
        const tfn = getUrlParameter('tfn') || '';
        fuse('meta', { campaignId, tfn });
      });
    }
  }, [updateFuse]);

  useEffect(() => {
    // Set preamp & monarch response in LogRocket
    window?.cohesion('preamp:decisionsReceived', () => {
      try {
        LogRocket.track('tracking', {
          preampExp: window?._Preamp?.actions?.experienceNumber || 0,
          monarchExp:
            monarchResponse?.hughesNetJORuleCARTMODELTEST?.experienceNumber ||
            0,
        });
      } catch (error) {
        logError(error);
      }
    });
  }, [preampDecisions, monarchResponse]);

  return (
    <>
      {/* Initialize Scripts */}
      <NewRelic />
      <CohesionScript src="https://cdn.cohesionapps.com/cohesion/cohesion-latest.min.js" />
      {process.env.ENVIRONMENT === 'production' && <Script src="/js/gtm.js" />}

      <Provider store={store}>
        <LegalProvider>
          <CallCenterProvider>
            {/* Home | Header */}
            {/* Without this div, the header will no longer be sticky */}
            <div style={{ position: 'sticky', top: 0, zIndex: 10 }}>
              <Preamp placement="11Dkf8jk8NTB736rAZhnP2">
                <NavHeader />
              </Preamp>
            </div>

            <main>
              <Component {...pageProps} />
            </main>
            {/* Home | Footer */}
            <Preamp placement="7ndJV7yVZO8e5UyajOqUMF">
              <Footer />
            </Preamp>
          </CallCenterProvider>
        </LegalProvider>
      </Provider>
    </>
  );
}
// Runs whenever the app initializes
export const reportWebVitals = (metric) => {
  if (process.env.ENVIRONMENT !== 'production') {
    return;
  }

  if (metric.label === 'web-vital') {
    import('~/helpers/utils/cwv.js').then((module) => module.default(metric));
  }
};

MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
};

export default withCohesion({
  App: MyApp,
  assets: listOfPreampAssets,
  defaultFuseNumber: '8778796866',
  callMonarchWithPreamp,
});
